<template>
  <div class="admin-landing-stats">
    <!-- Petition Candidate -->
    <b-row v-if="isPetition && contentPublished">
      <b-col md>
        <b-card>
          <b-card-title> Your Petition Signatures </b-card-title>
          <div class="d-table">
            <div class="d-table-cell">
              <div class="stats-total">{{ petitionSignatures }}</div>
            </div>
            <div class="d-table-cell">
              <div class="stats-label">Petition Signatures</div>
              <router-link to="manage-petition">Manage</router-link>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Approved Candidate -->
    <b-row v-if="contentPublished">
      <b-col v-if="hasEndorsements" md="8">
        <b-card>
          <b-card-title>Your Endorsements</b-card-title>
          <b-row>
            <!-- <b-col>
              <div class="d-table">
                <div class="d-table-cell">
                  <div class="stats-total">{{ unApprovedEndorsements }}</div>
                </div>
                <div class="d-table-cell">
                  <div class="stats-label">Unsubmitted Endorsements</div>
                  <router-link to="manage-endorsements">Manage</router-link>
                </div>
              </div>
            </b-col> -->
            <b-col>
              <div class="d-table">
                <div class="d-table-cell">
                  <div class="stats-total">{{ approvedEndorsements }}</div>
                </div>
                <div class="d-table-cell">
                  <div class="stats-label">Endorsements</div>
                  <router-link to="manage-endorsements">Manage</router-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card>
          <b-card-title>Your Events</b-card-title>
          <b-row>
            <b-col>
              <div class="d-table">
                <div class="d-table-cell">
                  <div class="stats-total">{{ events }}</div>
                </div>
                <div class="d-table-cell">
                  <div class="stats-label">Created Events</div>
                  <router-link to="manage-events">Manage</router-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPetition: false,
      unApprovedEndorsements: 0,
      approvedEndorsements: 0,
      events: 0,
      petitionSignatures: 0,
    };
  },
  async created() {
    if (this.$store.state.candidates.candidate == null) {
      await this.$store.dispatch(
        "candidates/getCandidate",
        this.$route.params.id
      );
    }
    this.isPetition = this.$store.state.candidates.candidate.isPetition;
    this.unApprovedEndorsements =
      this.$store.state.candidates.candidate.endorsements.filter(function (
        item
      ) {
        return item.dateSubmitted === null;
      }).length;
    this.approvedEndorsements =
      this.$store.state.candidates.candidate.endorsements.filter(function (
        item
      ) {
        return item.dateSubmitted !== null;
      }).length;
    this.events = this.$store.state.candidates.candidate.events.length;
    this.petitionSignatures =
      this.$store.state.candidates.candidate.petitions.filter(
        (x) => x.dateSubmitted == null
      ).length;
    this.loading = false;
  },
  computed: {
    contentPublished() {
      return this.$store.getters["candidates/candidate"].hasPublishedContent;
    },
    hasEndorsements(){
      return this.$store.state.candidates.candidate.manageEndorsementList.length > 0
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-landing-stats {
  .d-table {
    margin-top: 15px;
  }

  .d-table-cell {
    vertical-align: top;
  }

  .stats-label {
    font-weight: 600;
  }

  .stats-total {
    font-family: $montserrat;
    font-size: 32px;
    font-weight: 700;
    color: $mediumgray;
    background-color: $lightgray;
    padding: 0px 10px;
    border-radius: 10px;
    margin-right: 20px;
  }
}
</style>
